<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'adjustmentSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content bg-white border0">
          <div class="cancel-watermark" v-if="details.canceled_by">
            <div class="water-mark-content">Cancelled</div>
          </div>
          <div class="slip-ribbon ribbon-danger" v-else>
            <div class="slip-ribbon-text">{{ details.reason }}</div>
          </div>
          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Stock Adjustment Slip -{{ details.adjustment_id }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3" title="Print Slip">
                  <i class="fas fa-print"></i>
                </div>
                <div class="bill-head-icon mr-3" title="Download Slip">
                  <i class="fas fa-download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="closeSlipModal()"
                  title="Close Slip"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container">
            <div class="text-center" v-if="slipSettings.organization">
              <p class="font-xxl mb-0">{{ slipSettings.organization.name }}</p>
              <p class="mb-0">
                {{ details.branch.address }}
                <br />
                {{ details.branch.phone }} / {{ details.branch.email }}
                <br />
                PAN: {{ slipSettings.organization.pan_vat }}
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <p class="mg-b-0">Reason: {{ details.reason }}</p>
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4">
                <p class="mg-b-0 text-right">
                  Adjustment ID : {{ details.adjustment_id }}
                </p>
                <p class="mg-b-0 text-right">DATE : {{ details.bill_date }}</p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N.</th>
                    <th scope class="wd-50p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-15p">Purchase Rate</th>
                    <th scope class="wd-15p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>{{ item.item.name }}</td>
                    <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                    <td>{{ item.actual_purchase_rate }}</td>
                    <td class="tx-right">{{ item.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-0 col-lg-6 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <!-- Note -->
                <div
                  class="footer_detail mt-1 mb-0 p-2 ht-95p"
                  v-if="details.note"
                >
                  <label class="content-label mb-1">Note</label>
                  <p class="mb-2">{{ details.note }}</p>
                </div>
                <!-- Note -->
              </div>
              <!-- col -->
              <div
                class="
                  col-sm-4 col-lg-4
                  order-1 order-sm-0
                  color-secondary
                  mb-0
                  mt-0
                "
              >
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span>{{ details.total }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <p class="text-right">User: {{ details.user_detail.name }}</p>
              <p class="tx-danger text-right" v-if="details.canceled_by">Canceled By: {{details.canceled_user.name}}</p>
              <p class="tx-danger text-right" v-if="details.canceled_date">Canceled Date: {{details.canceled_date}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  computed: {
    ...mapGetters(["dataLists", "dataId", "modalId", "modalMode"]),
    ...mapGetters("stockAdjustment", ["dataSets", "slipSettings"]),
  },
  methods: {
    print(id) {
      //firing the event to parent component
      this.$emit("printSlip", id);
    },
    closeSlipModal() {
      if (this.$route.name == "edit-stock-adjustment") {
        this.$router.push({ name: "list-stock-adjustment" });
      }
      this.$store.dispatch("modalClose");
    },
  },
  watch: {
    dataId(value) {
      this.details = this.dataSets.find(function (data) {
        return data.id == value;
      });
    },
  },
};
</script>